import React, { useEffect, useState } from 'react'
import { useApi, useConfig, useLanguage } from '~components'
import { HelmetTags } from '../../components/HelmetTags'
import settings from '../../config'
import { BusinessesMap } from './components/BusinessesMap'

import {
  LocationsContainer,
  TitleContainer,
  MapWrap,
  MapWrapper,
  LocationsWrap,
  LocationsTitle,
  LocationsStoresWrapper,
  LocationsStoreCard,
  LocationsCardValue,
  LocationsCardKey
} from './styles'

export const LocationsPage = () => {
  const [ordering] = useApi()
  const [{ configs }] = useConfig()
  const [, t] = useLanguage()
  const [businesses, setBusinesses] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedBusiness, setSelectedBusiness] = useState(null)
  const [mapCenter, setMapCenter] = useState(null)

  const getBusinesses = async () => {
    try {
      const { content: { error, result } } = await ordering.businesses().asDashboard().select(['id', 'name', 'slug', 'location', 'address', 'phone', 'enabled', 'metafields', 'logo', 'delivery_price', 'distance']).get({
        headers: {
          'x-api-key': 'goAPG97r9Vx9h1qX8vCkTdsLyTbPSpb2CwVzeBjL_ST_V1VcPldSaq-ucicLAAV_O'
        }
      })
      if (!error) {
        let filterBySlug = null
        const franchiseId = parseInt(settings?.franchiseSlug)

        if (!isNaN(franchiseId)) {
          if (franchiseId === 1) {
            filterBySlug = 'coldstone'
          } else if (franchiseId === 2) {
            filterBySlug = 'pinkberry'
          }
        }

        const filteredBusinesses = filterBySlug
          ? result.filter(business => business.slug.startsWith(filterBySlug) && business.enabled)
          : result

        setBusinesses(filteredBusinesses)
      }
    } catch (err) {
      console.error('Error loading businesses:', err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getBusinesses()
  }, [])

  const handleBusinessClick = (business) => {
    setSelectedBusiness(business)
    if (business?.location) {
      setMapCenter({
        lat: typeof business.location.lat === 'string'
          ? parseFloat(business.location.lat)
          : business.location.lat,
        lng: typeof business.location.lng === 'string'
          ? parseFloat(business.location.lng)
          : business.location.lng
      })
    }
  }

  const defaultLocation = {
    lat: configs?.location_default_latitude?.value ? Number(configs?.location_default_latitude?.value) : 40.7744146,
    lng: configs?.location_default_longitude?.value ? Number(configs?.location_default_longitude?.value) : -73.9678064
  }

  return (
    <>
      <HelmetTags page='locations' />
      <LocationsContainer>
        <TitleContainer>
          <LocationsTitle className='locations-main-title'>
            {t('OUR_LOCATIONS', 'Our Locations')}
          </LocationsTitle>
        </TitleContainer>

        <MapWrap>
          <MapWrapper id='map-canvas'>
            {!loading && businesses.length > 0 && (
              <BusinessesMap
                businessList={businesses}
                userLocation={mapCenter || defaultLocation}
                onBusinessClick={handleBusinessClick}
                selectedBusiness={selectedBusiness}
                forceCenter={mapCenter !== null}
              />
            )}
          </MapWrapper>
        </MapWrap>

        <LocationsTitle className='locations-main-title' alignLeft>
          {t('OTHER_LOCATIONS', 'Other Locations')}
        </LocationsTitle>
        <LocationsWrap>
          <LocationsStoresWrapper>
            {businesses.map(business => (
              business.enabled && (
                <LocationsStoreCard
                  key={business.id}
                  onClick={() => handleBusinessClick(business)}
                  isSelected={selectedBusiness?.id === business.id}
                >
                  <LocationsTitle>{business.name}</LocationsTitle>
                  {business.metafields?.map(metafield => (
                    <LocationsCardValue key={metafield.key}>
                      <LocationsCardKey>{metafield.key}: </LocationsCardKey>
                      {metafield.value}
                    </LocationsCardValue>
                  ))}
                  <LocationsCardValue>
                    <LocationsCardKey>{t('ADDRESS', 'Address')}: </LocationsCardKey>
                    {business.address}
                  </LocationsCardValue>
                  <LocationsCardValue>
                    <LocationsCardKey>{t('PHONE', 'Phone')}: </LocationsCardKey>
                    {business.phone}
                  </LocationsCardValue>
                </LocationsStoreCard>
              )
            ))}
          </LocationsStoresWrapper>
        </LocationsWrap>
      </LocationsContainer>
    </>
  )
}

export default LocationsPage
