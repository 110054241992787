import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTheme } from 'styled-components'
import helmetData from '../../helmetdata.json'
import settings from '../../config'
import { useConfig } from '~components'

export const HelmetTags = (props) => {
  const {
    page
  } = props

  const {
    REACT_APP_OG_TITLE,
    REACT_APP_OG_DESCRIPTION,
    REACT_APP_OG_URL,
    REACT_APP_OG_SITE_NAME,
    REACT_APP_OG_IMAGE
  } = process.env

  const theme = useTheme()
  const [configs] = useConfig()
  const enabledPoweredByOrdering = configs?.powered_by_ordering_module?.value || (configs?.plan_enterprise && configs?.plan_enterprise?.value)
  const metaTag = page ? helmetData[page] : helmetData.app

  useEffect(() => {
    if (!theme?.third_party_code?.head) return

    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = theme.third_party_code.head

    const scripts = tempDiv.querySelectorAll('script')
    scripts.forEach(script => {
      const scriptExists = [...document.head.querySelectorAll('script')].some(existingScript => {
        if (script.src && existingScript.src === script.src) {
          return true
        }

        if (!script.src && existingScript.innerHTML === script.innerHTML) {
          return true
        }

        return false
      })
      if (!scriptExists) {
        const newScript = document.createElement('script')
        if (script.src) {
          newScript.src = script.src
        } else {
          newScript.innerHTML = script.innerHTML
        }
        document.head.appendChild(newScript)
      }
    })
  }, [theme?.third_party_code?.head])

  const noscriptContent = (() => {
    if (!theme?.third_party_code?.head) return ''
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = theme.third_party_code.head
    const noscript = tempDiv.querySelector('noscript')
    return noscript ? noscript.innerHTML : ''
  })()

  return (
    <Helmet titleTemplate={!page ? '' : `${theme?.my_products?.components?.website_settings?.components?.values?.name || settings.app_name} - %s`}>
      <title id={`route-${page}`}>{props.helmetMetaTags?.title || metaTag.title} {enabledPoweredByOrdering ? '- Powered by OrderingPlus' : ''}</title>
      <meta name='description' content={(theme?.my_products?.components?.website_settings?.components?.values?.description || props.helmetMetaTags?.description || metaTag.description) + (enabledPoweredByOrdering ? ' Powered by OrderingPlus' : '')} />
      <meta name='keywords' content={props.helmetMetaTags?.keywords || metaTag.keywords} />
      {props.robots
        ? (
        <meta name='robots' content={props.robots} />
          )
        : (
            metaTag.robots && <meta name='robots' content={metaTag.robots} />
          )}
      {props.canonicalUrl
        ? (
        <link rel='canonical' href={props.canonicalUrl} />
          )
        : (
            metaTag.canonicalUrl && <link rel='canonical' href={metaTag.canonicalUrl} />
          )}
      {noscriptContent && (
        <noscript>{noscriptContent}</noscript>
      )}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={REACT_APP_OG_TITLE} />
      <meta property="og:description" content={REACT_APP_OG_DESCRIPTION} />
      <meta property="og:url" content={REACT_APP_OG_URL} />
      <meta property="og:site_name" content={REACT_APP_OG_SITE_NAME} />
      <meta property="og:image" content={REACT_APP_OG_IMAGE} />
      <meta property="og:image:type" content="image/png" />
    </Helmet>
  )
}

export default HelmetTags
