import React, { useState, useEffect } from 'react'
import { renderToString } from 'react-dom/server'
import { useTheme } from 'styled-components'
import { useConfig } from '~components'
import { GoogleMaps } from '../GoogleMaps'
import settings from '../../../../config'
import {
  WrapperMap,
  ContainerCard,
  WrapperBusinessCard,
  BusinessHero,
  BusinessContent,
  BusinessInfo,
  BusinessName
} from './styles'

export const BusinessesMap = (props) => {
  const {
    businessList,
    userLocation,
    onBusinessClick,
    selectedBusiness,
    forceCenter
  } = props

  const [infoWindow, setInfoWindow] = useState(null)
  const [{ configs }] = useConfig()
  const theme = useTheme()

  const locations = businessList?.map(business => ({
    ...business,
    lat: business.location.lat,
    lng: business.location.lng,
    icon: theme?.images?.general?.mapPin,
    markerPopup: renderToString(
      <ContainerCard className='business-map-card'>
        <WrapperBusinessCard>
          <BusinessHero>
            <BusinessName className='business-name-map'>{business?.name}</BusinessName>
            <BusinessContent>
              <BusinessInfo className='business-tel-map'>
                <div className='business-tel-map-label'>Phone number</div>
                <a href={`tel:${business?.phone}`}>
                  {business?.phone}
                </a>
              </BusinessInfo>
            </BusinessContent>
          </BusinessHero>
        </WrapperBusinessCard>
      </ContainerCard>
    ),
    onBusinessCustomClick: () => onBusinessClick(business)
  }))

  useEffect(() => {
    if (selectedBusiness) {
      const selectedLocation = locations?.find(loc => loc.id === selectedBusiness.id)
      if (selectedLocation) {
        setInfoWindow({
          location: selectedLocation,
          content: selectedLocation.markerPopup
        })
      }
    } else {
      setInfoWindow(null)
    }
  }, [selectedBusiness])

  const googleMapsControls = {
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeId: 'roadmap',
    mapTypeControl: false
  }

  return (
    <WrapperMap colorTitle={parseInt(settings?.franchiseSlug) === 1 ? '#840612' : '#7DBE36'}>
      <GoogleMaps
        apiKey={configs?.google_maps_api_key?.value}
        location={userLocation}
        locations={locations}
        mapControls={googleMapsControls}
        activeInfoWindow={infoWindow}
        forceCenter={forceCenter}
      />
    </WrapperMap>
  )
}
