import styled, { css } from 'styled-components'

export const LocationsContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TitleContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`

export const MapWrap = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

export const MapWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  > div {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-width: 768px) {
    height: 300px;
  }
`

export const LocationsWrap = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LocationsTitle = styled.h3`
  text-transform: capitalize;
  font-size: 20px;
  margin: 0;
  color: ${props => props.theme?.colors?.primary};
  font-size: 30px;
  margin-bottom: 20px;

  ${props => props.alignLeft && css`
    width: 90%;
    text-align: left;
  `}
`

export const LocationsStoresWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`

export const LocationsStoreCard = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #E9ECEF;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${props => props.isSelected ? '#F8F9FA' : '#FFFFFF'};
  border-color: ${props => props.isSelected ? '#DEE2E6' : '#E9ECEF'};
  box-shadow: 0px 3px 10px rgba(0, 0, 0, .15);

  &:hover {
    background-color: #F8F9FA;
    border-color: #DEE2E6;
  }
`

export const LocationsCardValue = styled.p`
  margin: 5px 0;
  text-align: left;
`

export const LocationsCardKey = styled.span`
  text-transform: capitalize;
  font-weight: 500;
  color: ${props => props.theme?.colors?.primary};
`

export const PopupContainer = styled.div`
  padding: 8px;
`

export const PopupTitle = styled.h3`
  margin: 0 0 8px;
  color: ${props => props.theme?.colors?.primary};
  font-size: 16px;
  text-transform: capitalize;
`

export const PopupContent = styled.div`
  margin: 0;
`

export const PopupPhone = styled.p`
  margin: 0;

  span {
    color: ${props => props.theme?.colors?.primary};
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`
