import React from 'react'

export const getUtils = () => {
  const TagOrders = () => {
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="155.000000pt" height="155.000000pt" viewBox="0 0 155.000000 155.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,155.000000) scale(0.100000,-0.100000)"
        fill="currentColor" stroke="none">
        <path d="M327 1533 c-4 -3 -7 -84 -7 -179 l0 -173 -152 -3 -153 -3 0 -520 c0
        -587 -3 -566 77 -622 l41 -28 641 0 c723 0 686 -4 743 77 l28 41 3 695 c2 512
        0 698 -9 708 -10 12 -112 14 -609 14 -328 0 -600 -3 -603 -7z m1173 -711 c0
        -627 -1 -669 -18 -701 -10 -19 -34 -43 -53 -53 -31 -17 -70 -18 -571 -18
        l-537 0 24 46 25 47 0 673 0 674 565 0 565 0 0 -668z m-1330 -177 l0 -485 25
        0 25 0 0 485 0 485 50 0 50 0 0 -485 c0 -524 -1 -536 -53 -570 -42 -27 -89
        -29 -134 -7 -74 38 -73 27 -73 576 l0 486 55 0 55 0 0 -485z"/>
        <path d="M822 1346 c-37 -34 -37 -35 -40 -125 l-4 -91 -53 0 c-30 0 -57 -5
        -63 -12 -11 -14 11 -629 23 -648 7 -12 524 -14 542 -2 17 10 45 631 30 650 -5
        7 -32 12 -63 12 l-54 0 0 70 c0 81 -16 128 -55 158 -24 19 -40 22 -126 22 -99
        0 -99 0 -137 -34z m247 -42 c17 -22 21 -41 21 -100 l0 -74 -130 0 -130 0 0 74
        c0 59 4 78 21 100 19 25 25 26 109 26 84 0 90 -1 109 -26z m-289 -289 c0 -63
        1 -65 25 -65 24 0 25 2 25 65 l0 65 130 0 130 0 0 -65 c0 -63 1 -65 25 -65 24
        0 25 2 25 65 l0 65 36 0 37 0 -7 -217 c-3 -120 -9 -248 -12 -285 l-7 -68 -228
        0 c-126 0 -229 1 -230 3 -4 10 -25 554 -22 560 2 4 20 7 39 7 l34 0 0 -65z"/>
        <path d="M520 335 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25 -20
        0 -25 -5 -25 -25z"/>
        <path d="M630 335 l0 -25 380 0 380 0 0 25 0 25 -380 0 -380 0 0 -25z"/>
        <path d="M527 253 c-4 -3 -7 -15 -7 -25 0 -17 20 -18 435 -18 l435 0 0 25 0
        25 -428 0 c-236 0 -432 -3 -435 -7z"/>
        </g>
      </svg>
    )
  }
  return {
    TagOrders
  }
}
