import styled from 'styled-components'

export const WrapperMap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .business-name-map {
    color: ${props => props.colorTitle};
  }

  .business-tel-map {
    a {
      color: ${props => props.theme?.colors?.primary};
    }
  }

  .gm-style .gm-style-iw-c {
    padding: 0 !important;
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }
`

export const ContainerCard = styled.div`
  padding: 0px 10px 20px 10px;
`

export const WrapperBusinessCard = styled.div`
  max-width: 250px;
`

export const BusinessHero = styled.div`
`

export const BusinessContent = styled.div`
`

export const BusinessInfo = styled.div`
  margin-top: 5px;
`

export const BusinessInfoItem = styled.div`
  font-size: 14px;
`

export const BusinessName = styled.h3`
  font-size: 16px;
  margin: 0 0 5px;
  color: ${props => props.theme?.colors?.primary};
`

export const BusinessAddress = styled.p`
  margin: 0;
  font-size: 14px;
`
